import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

//import VueLoading from 'vue-loading-overlay';
//import 'vue-loading-overlay/dist/vue-loading.css';

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  //.use(VueLoading)
  .mount('#app')
