<template>
  <div>
    <v-card flat elevation="2">
      <div>
        <v-toolbar color="#ffffff" prominent>
          <v-btn to="/carta" icon class="hidden-xs-only">
            <v-icon color="#FF3E4F">mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title class="font-bold">{{selectedItem.titulo}}</v-toolbar-title>
        </v-toolbar>
      </div>
    </v-card>
    <v-container>
      <Product :products="selectedItem.products" />
    </v-container>
  </div>
</template>
<script>
import Product from "@/components/Product.vue";
import axios from "axios";
import { mapState } from 'vuex';

export default {
  name: "Detail",
  components: {
    Product,
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['carta', 'selectedItemId']),
    // Obtén el elemento seleccionado del estado de Vuex
    selectedItem() {
      return this.carta.find((item) => item.id === this.selectedItemId);
    },
    // Obtén los productos del elemento seleccionado o un arreglo vacío si no se encuentra
    products() {
      return this.selectedItem ? this.selectedItem.products : [];
    },
    titulo() {
      return this.selectedItem ? this.selectedItem.titulo : '';
    },
  },
};
</script>
<style scoped>
.v-container{
  padding: 0px 16px 16px 16px;
}
</style>
