<template>
  <v-img src="../assets/carne-fuego/fondo2.png" cover height="100vh" class="position-relative">
    <v-btn to="/" variant="text" color="#FFFFFF" size="x-large" class="mt-10" style="position: absolute; left: 0">
      <v-icon color="#FF3E4F" size="x-large">mdi-arrow-left</v-icon>
    </v-btn>
    <v-img src="../assets/carne-fuego/logo-carne-fuego.svg" contain class="mt-top tm-logo mb-3 mx-auto"></v-img>
    <v-container style="overflow-y: auto; padding-bottom: 30px;" class="mt-mobile">
      <v-row class="mx-0 mt-2 mb-3">
        <v-col cols="4" lg="4" md="4" v-for="item in carta">
          <v-card class="text-center" height="65" @click="navigateToDetail(item.id)" style="border-radius: 9.878px;
border: 0.988px solid #212121; ">
            <div class="d-flex flex-column align-center justify-center w-100" style="width: 100%">
              <div>
                <img :src="item.image" alt="" class="d-flex align-center justify-center" style="
                    object-fit: scale-down;
                    margin: auto;
                    width: 85px;
                    height: 55px;
                  " />
              </div>
              <!-- <v-img :src="item.image" contain height="70" width="70"></v-img> -->
              <!-- <div style=" line-height: 70%;">
                <small class="text-center font-bold" style="font-size: 10px;">{{
                  item.titulo
                }}</small>
              </div> -->
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" lg="4" md="4">
        <v-card class="text-center v-card--flat" height="85" style="background-color: transparent !important; box-shadow: none !important;">
          <div class="d-flex flex-column align-center justify-center w-100" style="width: 100%">
            <div>
              <!-- <img src="../assets/no-restaurant.png" alt="" class="d-flex align-center justify-center" style="
                object-fit: scale-down;
                margin: auto;
                width: 50px;
                height: 50px;
              " /> -->
              <!-- <v-img src="../assets/no-restaurant-new.png" alt="" class="d-flex align-center justify-center" style="
                object-fit: scale-down;
                margin: auto;
                width: 63px;
                height: 63px;
              "></v-img> -->
            </div>
          </div>
        </v-card>
      </v-col>
      </v-row>

    </v-container>
    <!-- <v-container>
      <Footer />
    </v-container> -->

  </v-img>
</template>
<style>
.v-col-4 {
  padding: 8px;
}
.v-btn:hover > .v-btn__overlay{
  opacity: 0 !important;
}
</style>
<style scoped>
@media screen and (max-width: 375px) {
  .mt-top{
    /* margin-top: 35px !important; */
    margin-top: 80px !important;
    margin-bottom: 0px !important;
  }
  .tm-logo{
  width: 8rem !important;
  }
  .mt-mobile{
    margin-top: -10px !important;
  }
}
.mt-top{
  margin-top: 70px;
}
.tm-logo{
  width: 9rem;
}
.v-col-3,
.v-col-lg-3,
.v-col-md-3 {
  width: 100%;
  padding: 5px;
}

.v-card {
  display: -webkit-box;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-radius: 4.9px;
  background: var(--niubiz-white, #fff);
  box-shadow: 0px 0px 7.840799331665039px 0px rgba(0, 0, 0, 0.16);
}

.v-btn__content {
  text-transform: none;
}
.footer {
        position: fixed;
        display: flex;
        flex-direction: row;
        align-items: center;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        padding: 10px;
        }
</style>
<script>
import { defineComponent } from "vue";
import { mapMutations } from 'vuex';
import { mapState } from 'vuex';
// Components

import Footer from "@/components/Footer.vue";
export default {
  name: "Carta",
  components: {
    Footer,
  },
  data: () => ({
   
  }),
  computed: {
    ...mapState(['carta']),
  },
  methods: {
    ...mapMutations(['setSelectedItemId']),
    navigateToDetail(id) {
      this.setSelectedItemId(id); // Almacenar el ID seleccionado en el estado de Vuex
      this.$router.push({ path: `/detail/${id}` }); // Navegar a la página de detalle
    },
  },
};
</script>
