<template>
    <v-row class="my-3">
        <v-col cols="6" md="6" v-for="product in products" :key="product.id">
        <v-card class="mx-auto v-card-t d-flex flex-column" height="100%">
            <!-- <v-img v-if="product.available_stock <= 0" :src="product.photo_url" class="v-img-agot ma-auto d-flex align-center justify-center" cover  justify="center" align="center">
                    <v-chip color="#545454"
                        text-color="#ffffff" style="background-color: #ffffff;font-weight: 700;">AGOTADO</v-chip>
            </v-img> -->
            <div >
                <v-img :src="product.image"  cover></v-img>
            </div>
            <v-card-title style="height: 140px;">
            <div class="font-bold" style="font-size: 20px; line-height: 120%; display: flex;">
                <span v-html="formattedProductName(product.name)"></span>
            </div>
            </v-card-title>
            <v-card-text>
            <p style="color: #545454; font-size: 16px; line-height: 130%;;" class="font-regular">
                {{ product.description }}
            </p>
            </v-card-text>
            <v-card-actions class="mt-auto">
            <div class="font-bold px-3" style="color: #03A9F4;">
                S/ {{ product.price }}
            </div>
            </v-card-actions>
        </v-card>
        </v-col>
    </v-row>
</template>
<style>

.v-img-agot .v-img__img--cover {
    opacity: .25 !important;
}

</style>

<style scoped>


.v-col-6,
.v-col-lg-6,
.v-col-md-6 {
  width: 100%;
  padding: 5px;
}
.v-card-title {
    white-space: initial !important;
}
.v-card .v-card-title {
    line-height: 1.5rem;
    padding: 1rem 0.8rem 0.5rem 0.8rem;
}
.v-card .v-card-text{
    padding: 0rem 0.8rem 0rem 0.8rem;
}
.white--text {
    color: white;
}
.v-card-t {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 10px;    
}
.subtitle-2 {
    color: #070D56;
}

</style>
<script>
export default {
    name: 'Product',
    props: {
        products: {
        type: Array,
        required: true,
        },
    },
    methods: {
        formattedProductName(name) {
        return name.replace(/\n/g, '<br>');
        },
    },
}
</script>
