<template>
      <v-img src="../assets/carne-fuego/fondo2.png" cover min-height="100%" class="position-relative">
        <v-img src="../assets/carne-fuego/logo-carne-fuego.svg" contain class="mt-top tm-logo mb-3 mx-auto"></v-img>
        <v-container class="menu">
        <div
            class="subtitle-1 font-bold text-center mb-0 pb-4 text-white"
        >
            <!-- Mientras esperas, <br />
            ve revisando nuestra carta -->
        </div>
        <div class="d-flex flex-column">
            <v-btn to="/carta" color="#FFFFFF" prepend-icon="mdi-silverware-fork-knife" size="x-large" class="btn-main font-bold my-4">Nuestra carta</v-btn>
            <v-btn to="/como-comprar" color="#FFFFFF" prepend-icon="mdi-cart-outline"  size="x-large" class="btn-main font-bold">Cómo comprar</v-btn>
        </div>
        <Footer/>
        </v-container>
    </v-img>
</template>
<style scoped>
@media screen and (max-width: 375px) {
    .mt-top{
        margin-top: 16rem!important;
    }
    .subtitle-1{
        /* margin-top: 25px !important; */
    }
}
.tm-logo{
  width: 210px;
}
.v-btn--size-x-large {
    --v-btn-size: 1.125rem;
    --v-btn-height: 80px;
    font-size: 1.25rem;
    min-width: 92px;
    padding: 0 24px;
}
.menu {
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.mt-top{
    margin-top: 110px;
}
 .subtitle-1{
    margin-top: 55px ;
}
.footer {
        position: fixed;
        display: flex;
        flex-direction: row;
        align-items: center;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparent; /* Puedes ajustar el color de fondo según tus necesidades */
        padding: 10px;
        }
    .btn-main {
        border-radius: 10px;
        background: var(--niubiz-white, #FFF);
        text-transform: none;
        color: #000;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
    }
</style>
<script>
import Footer from "@/components/Footer.vue";
export default {
    name: "Home",
    components: {
        Footer,
    }
}
</script>