<template>
  <v-app>
    <v-main height="100vh">

      <router-view  :is-authenticated="isAuthenticated"/>
    </v-main>
    
  </v-app>
</template>
<style>
@font-face {
  font-family: 'Objective-Bold';
  src: url('./assets/fonts/Objective_Woff2/Objective-Bold.woff2'); /* Reemplaza 'mi-fuente.ttf' con la ruta y el nombre de tu archivo de fuente */
}


@font-face {
  font-family: 'Objective-Medium';
  src: url('./assets/fonts/Objective_OTF/Objective-Medium.otf'); /* Reemplaza 'mi-fuente.ttf' con la ruta y el nombre de tu archivo de fuente */
}

@font-face {
  font-family: 'Objective-Regular';
  src: url('./assets/fonts/Objective_OTF/Objective-Regular.otf'); /* Reemplaza 'mi-fuente.ttf' con la ruta y el nombre de tu archivo de fuente */
}

body{
  font-family: 'Objective-Regular', sans-serif;
}
.font-regular {
  
  font-family: 'Objective-Regular', sans-serif;
}
.font-bold {
  
  font-family: 'Objective-Bold', sans-serif;
}
.v-main {
  max-height: 100vh;
}
</style>
<script>
import axios from 'axios';
export default {
  name: 'App',
  created() {
    this.login();
  },
  methods: {
    login() {
      const email = 'clientemulticomercio@email.com';
      const password = 'Kipu12!!';
      console.log(process.env.WS_USER_LOGIN);
      // Realizar la petición al API de login
      axios.post('https://ws-kipu-reload.startapps.com.pe/api/auth/login', {
        email: email,
        password: password,
        roles_simple_array: true
      })
        .then(response => {
          const token = response.data.token;
          localStorage.setItem('token', token);

          this.$store.commit('setAuthentication', true);
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  computed: {
    isAuthenticated() {

      const token = localStorage.getItem('token');
      const isAuthenticated = token !== null && token !== undefined;
      console.log('isAuthenticated:', isAuthenticated);
      return isAuthenticated;
    },
  },
}
</script>
