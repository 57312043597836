<template>
    <v-img src="../assets/carne-fuego/fondo2.png" cover height="100vh">
          <v-btn to="/" variant="text" color="#FFFFFF" size="x-large" class="mt-10" style="position: absolute; left: 0;">
            <v-icon color="#FF3E4F"  size="x-large">mdi-arrow-left</v-icon>
          </v-btn>
        <v-img src="../assets/carne-fuego/logo-carne-fuego.svg" contain class="tm-logo mt-top mb-3 mx-auto"></v-img>
        <v-container>
            <v-carousel height="auto"
            :continuous="false"
            :show-arrows="false"  v-model="activeSlide">
            <v-carousel-item class="h-slides">
                <v-img  src="../assets/carne-fuego/Carneyfuego.gif" contain  class="h-img"></v-img>
                <div class="text-dark my-5 text-center font-bold fz-mobile">
                    <div>1. Selecciona los productos de tus<br> marcas preferidas y agrégalos<br> al carrito de compras</div>
                </div>
            </v-carousel-item>

            <v-carousel-item class="h-slides">
                <v-img  src="../assets/Carrito-boleta-new.gif" contain  class="h-img"/>
                <div class="text-dark my-5 text-center font-bold fz-mobile">
                    2. Revisa el resumen de tu <br> pedido y selecciona el <br>tipo de comprobante
                </div>
            </v-carousel-item>
            <v-carousel-item class="h-slides">
                <v-img  src="../assets/paso-3-last.png" contain  class="h-img"/>
                <div class="text-dark my-5 text-center font-bold fz-mobile">
                    3. Selecciona el medio<br>
de pago que deseas
                </div>
            </v-carousel-item>
            <v-carousel-item class="h-slides">
                <v-img  src="../assets/paso-4-new.png" contain  class="h-img"/>
                <div class="text-dark my-5 text-center font-bold fz-mobile">
                    4. Para pago con QR,<br>
escanea el código, espera unos<br> segundos o presiona el botón<br> “Continuar”
                </div>
            </v-carousel-item>
            <v-carousel-item class="h-slides">
                <v-img  src="../assets/paso5.png" contain  class="h-img"/>
                <div class="text-dark my-5 text-center font-bold fz-mobile">
                    5. Para pago con tarjetas,<br>
 acerca tu tarjeta a la cabecera del <br> Pinpad, desliza o insértala
                </div>
            </v-carousel-item>
            <v-carousel-item class="h-slides">
                <v-img  src="../assets/paso6.png" contain  class="h-img"/>
                <div class="text-dark my-5 text-center font-bold fz-mobile">
                    6. Finalmente, recibe tus tickets.<br>
Recuerda conservarlos para poder<br> reclamar tus productos
                </div>
            </v-carousel-item>
            </v-carousel>
        </v-container>
        <div class="dots">
        <span
            v-for="(slide, index) in slides"
            :key="index"
            :class="{ active: activeSlide === index }"
            @click="activeSlide = index"
        ></span>
        </div>
    </v-img>
</template>

<style scoped>
/* Estilos existentes */

@media screen and (max-width: 375px) {
    .h-img{
        max-height: 320px !important;
    }
    .tm-logo{
        width: 9rem !important;
    }
    .mt-top{
        /* margin-top: 35px !important; */
        margin-top: 80px !important;
        margin-bottom: 0px !important;
    }
    .dots{
        margin-top: -0.5rem !important;
    }
    .fz-mobile{
        font-size: 14px !important;
    }
}
.mt-top{
  margin-top: 70px;
}
.h-img{
    max-height: 380px ;
}
.h-slides{
    height: 500px;
}
.tm-logo{
    width: 9rem;
}

.v-btn--size-x-small {
  --v-btn-size: 0.625rem;
  --v-btn-height: 12px;
  font-size: 0.35rem;
  min-width: 36px;
  padding: 0 8px;
}

.mdi-circle {
  color: #ffffff59;
}

.dots {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.dots span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FF7A00;
  opacity: 0.4; 
  margin: 0 5px;
  cursor: pointer;
}

.dots span.active {
  background-color: #FF7A00; 
  opacity: 1;
}
.v-btn--size-x-small {
    --v-btn-size: 0.625rem;
    --v-btn-height: 12px;
    font-size: 0.35rem;
    min-width: 36px;
    padding: 0 8px;
}
.v-btn--size-x-small {
    --v-btn-size: 0.625rem;
    --v-btn-height: 12px;
    font-size: 0.35rem;
    min-width: 36px;
    padding: 0 8px;
}
</style>
<style>
.v-carousel__controls {
    display: none;
}
.mdi-circle {
    color: #ffffff59;
}
</style>
<script>
import Footer from "@/components/Footer.vue";
export default {

    name: "Comprar",
    components: {
        Footer,
    },  
    data() {
        return {
        activeSlide: 0,
        slides: 6, // Número total de slides que tienes en el carrusel
        };
    },
}
</script>