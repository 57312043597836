<template>
    
    <div class="footer d-flex flex-row pb-4 mt-5">
                <v-img src="../assets/filo-chifa/filo.png" class="" contain height="45px"/>
                <v-spacer></v-spacer>
                <v-img src="../assets/filo-chifa/niubiz.png" class="" contain height="18px"/>
            
        </div>
</template>
<script>
export default {
    name: 'Footer',
}
</script>