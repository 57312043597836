import { createRouter, createWebHistory } from 'vue-router'
import Carta from '../views/Carta.vue'
import Detail from '../views/Detail.vue'
import Home from '../views/Home.vue'
import Comprar from '../views/Comprar.vue'
import store from '../store.js'




const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/carta',
    name: 'carta',
    component: Carta
  },
  {
    path: '/como-comprar',
    name: 'como-comprar',
    component: Comprar
  },
  {
    path: "/detail/:id",
    name: "detail",
    component: Detail,
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.state.carta.length > 0) {
        const id = parseInt(to.params.id);
        if (!isNaN(id)) {
          if (store.state.carta.some(item => item.id === id)) {
            store.commit('setSelectedItemId', id);
            next();
          } else {
            next({ name: 'home' });
          }
        } else {
          next({ name: 'home' });
        }
      } else {
        next({ name: 'home' });
      }
    },
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
