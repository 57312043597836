// store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    carta: [
      {
        id: 1,
        titulo: "El Jefe",
        image: require("@/assets/Logos-carne/jefe.jpeg"),
        products: [
          {
            name: "Beef back\n ribs special",
            description: "Costillas de res ahumadas con leña de pecano, con salsa alabama white, papas cocktail ahumadas, mantequilla de ajo y encurtidos.",
            price: "40",
            image: require("@/assets/carne-fuego/platos/El-jefe-Beef-Back-Rib-Special.png"),
          },
          { name: "Parmesan Garlic\n Wings", 
            description: "6 alitas ahumadas con leña de manzano, bañadas en nuestra salsa de mantequilla, parmesano y ajo ahumado.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/El-jefe-Parmesan-Garlic-Wings.png"),
          },
        ],
      },
      {
        id: 2,
        titulo: "La vaca loca",
        image: require("@/assets/Logos-carne/vacaloca.png"),
        products: [
          {
            name: "Lomo a la pimienta\n acompañado de papa campesina",
            description: "Lomo bañado en salsa pimienta acompañado de papa campesina salteada con cebolla blanca y perejil.",
            price: "48",
            image: require("@/assets/carne-fuego/platos/La-vaca-loca-Lomo-pimienta.png"),
          },
          { name: "Choripan en salsa de\n Grana Padano", 
            description: "Chorizo de la casa en pan francés,  acompañado de papas al hilo, salsa de queso Grana Padano y chimichurri.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/La-vaca-loca-choripan-padano.png"),
          },
        ],
      },
      {
        id: 3,
        titulo: "Primitivo Parrilleros",
        image: require("@/assets/Logos-carne/Primitivo_Logo-07.svg"),
        products: [
          {
            name: "Pachamanca Ahumada de\n Cerdo y Pollo",
            description: "Pachamanca de cerdo y pollo ahumada en madera de pecano y olivo con aderezo de hierbas andinas y tubérculos.",
            price: "42",
            image: require("@/assets/carne-fuego/platos/Primitivo-Pachamanca-Cerdo-Pollo.png"),
          },
          { name: "Dúo de choripanes al Vino en pan flauta español", 
            description: "Chorizo de finas hierbas con reducción de Vino y Chorizo Tradicional con 14 especias emulsionado en Vino Blanco.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Primitivo-Dúo-pan-español.png"),
          },
        ],
      },
      {
        id: 4,
        titulo: "La Cabrera",
        image: require("@/assets/Logos-carne/cabrera-last.svg"),
        products: [
          {
            name: "Vuelta y vuelta",
            description: "Tierno corazón de cuadril argentino grillado a alta temperatura a un solo término.",
            price: "39",
            image: require("@/assets/carne-fuego/platos/La-cabrera-Vuelta-vuelta.png"),
          },
          { name: "Anticuchos con papas\n a la provenzal", 
            description: "Nuestro tradicional plato a la parrilla con corazon de res argentina.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Anticucho-cabrera.jpeg"),
          },
        ],
      },
      {
        id: 5,
        titulo: "Costumbres Argentinas",
        image: require("@/assets/Logos-carne/costumbresarg.png"),
        products: [
          {
            name: "Bife de chorizo\n argentino ciudad del lago x200gr",
            description: "Bife de chorizo argentino Ciudad del lago x200gr, solo con sal, acompañado con pan y chimichurri. ",
            price: "50",
            image: require("@/assets/carne-fuego/platos/Costumbres-argentinas-Bife-angosto-argentino.png"),
          },
          { name: "Molleja de Res con chimichurri", 
            description: "Molleja de res a la parrilla con pan y chimichurri.",
            price: "10",
            image: require("@/assets/carne-fuego/platos/Costumbres-argentinas-Molleja-res-chimichurri.png"),
          },
        ],
      },
      {
        id: 6,
        titulo: "Osso",
        image: require("@/assets/Logos-carne/Osso_Logo-06.svg"),
        products: [
          {
            name: "Tira de asado\n angus",
            description: "200gr de tira de asado + porción de ensalada césar.",
            price: "50",
            image: require("@/assets/carne-fuego/platos/Osso-Tir-Angus.png"),
          },
          { name: "Bife angosto angus", 
            description: "200 gramos de bife angosto a la parrilla + 120 gramos de puré de papa amarilla trufado.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Osso-Bife-Angus.png"),
          },
        ],
      },
      {
        id: 7,
        titulo: "La Carniería de Tito",
        image: require("@/assets/Logos-carne/tito.jpg"),
        products: [
          {
            name: "Bondiola de 220 gr\n con gravy",
            description: "Bondiola 220gr ahumada y sellada a la brasa, con gravy.",
            price: "50",
            image: require("@/assets/carne-fuego/platos/Tito-Gondola-con-gravy.png"),
          },
          { name: "02 choripanes\n con chimi", 
            description: "Choripan clasico argentino.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Tito-choripanes-chimi.png"),
          },
        ],
      },
      {
        id: 8,
        titulo: "Gour Meat",
        image: require("@/assets/Logos-carne/gurmetnegro.png"),
        products: [
          {
            name: "Mollejas de res Argentina!\n Las mejores de Lima",
            description: "Hechas a la parrilla y con un toque de limón.",
            price: "38",
            image: require("@/assets/carne-fuego/platos/Gourmeat-Molleja-de-Res-Argentina.png"),
          },
          { name: "Meat Rolls", 
            description: "Rolls de Corazón de cuadril a la mostaza y tocino (05 unid).",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Gourmeat-Meat-Rolls.png"),
          },
        ],
      },
      {
        id: 9,
        titulo: "Café A Bistro",
        image: require("@/assets/Logos-carne/cafea.png"),
        products: [
          {
            name: "Café A\n Burger",
            description: "170gr de asado de tira molido grueso, cebolla caramelizada, queso cheddar y salsa secreta.",
            price: "30",
            image: require("@/assets/carne-fuego/platos/Cafe-bistro-Cafe-Burger.png"),
          },
          { name: "Smokey chili\n nachos", 
            description: "Nachos con chili de carne y salsa de queso.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Cafe-bistro-Smokey Chili-Nachos.png"),
          },
        ],
      },
      {
        id: 10,
        titulo: "Zoyla",
        image: require("@/assets/Logos-carne/zoylacolor.jpg"),
        products: [
          {
            name: "Bife de\n chorizo",
            description: "Bife chorizo importado x 200grs con salsa demi-glace ahumada acompañado con mac and cheese y trufa blanca.",
            price: "45",
            image: require("@/assets/carne-fuego/platos/Zoila- Bife-chorizo.png"),
          },
          { name: "Tacos de Pulled Pork", 
            description: "Brazuelo de cerdo ahumado por 12hrs y mechado con criolla encurtida de la casa y salsa verde de jalapeños, kiwi y culantro.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Zoila-Tacos-Pulled-Pork.png"),
          },
        ],
      },
      {
        id: 11,
        titulo: "Kilo",
        image: require("@/assets/Logos-carne/KILO2.jpg"),
        products: [
          {
            name: "Entraña\n Angus USA",
            description: "100 grs de entraña Angus, con puré al chimichurri.",
            price: "45",
            image: require("@/assets/carne-fuego/platos/Kilo-Entrana-Angus-USA.png"),
          },
          {
            name: "Chanchito\n Hoisin",
            description: "Chanchito cocido a baja temperatura por 48 horas y terminado en el Ahumador con mini arabitos y coleslaw.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Kilo-Chanchito-Hoisin.png"),
          },
        ],
      },
      {
        id: 12,
        titulo: "Baco y Vaca",
        image: require("@/assets/Logos-carne/BacoVaca_Logo-03.svg"),
        products: [
          {
            name: "Brocheta de\n bife",
            description: "dados de bife angosto argentino con cebolla y pimientos acompañado de papas nativas tumbay.",
            price: "30",
            image: require("@/assets/carne-fuego/platos/Baco-vaca-Brocheta-de-Bife.png"),
          },
          { name: "Costillas bbq\n Nikkei", 
            description: "Costillas de cerdo americano en salsa bbq Nikkei.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Baco-vaca-Costillas-BBQ-Nikkei.png"),
          },
        ],
      },
      {
        id: 13,
        titulo: "Cavenecia Steakhouse",
        image: require("@/assets/Logos-carne/C1.png"),
        products: [
          {
            name: "Colita de Cuadril Angus con chorizo",
            description: "200 gr de colita de cuadril angus argentina acompañado de chorizo de finas hierbas y chimichurri de la casa.",
            price: "40",
            image: require("@/assets/carne-fuego/platos/Cavenecia-Colita-de-Cuadril-Angus-con-Chorizo.png"),
          },
          { name: "Cheeseburger\n Cavenecia", 
            description: "150 gr de hamburguesa angus argentina en pan de la casa, acompañado de lechuga, tomate, aros de cebolla blanca, queso cheddar y salsa americana de la casa.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Cavenecia-Cheese-Burger-Cavenecia.png"),
          },
        ],
      },
      {
        id: 14,
        titulo: "Smoke Ft",
        image: require("@/assets/Logos-carne/3.png"),
        products: [
          {
            name: "Sticky pork\n belly",
            description: "Panceta Ahumada, bañada en miel oriental, sobre Chaufa blanco.",
            price: "32",
            image: require("@/assets/carne-fuego/platos/Smoki-Sticky-belly.png"),
          },
          { name: "Pork & coke\n sándwich", 
            description: "Bondiola Ahumada, bañada en bbq de coca cola, ensalada de col y cebolla crocante.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Smoki-pork-sandwich.png"),
          },
        ],
      },
      {
        id: 15,
        titulo: "Pico Negro Smoked",
        image: require("@/assets/Logos-carne/piconegro.png"),
        products: [
          {
            name: "Trío\n ahumado",
            description: "Bandeja con 3 tipos de carne ahumada, acompañada de ensalada de papa y coleslaw.",
            price: "39",
            image: require("@/assets/carne-fuego/platos/Pico-Negro-trio-Ahumado.png"),
          },
          { name: "Smoked pulled pork sandwich", 
            description: "Sandwich de bondiola ahumada de cerdo, mechada y envuelta en salsa bbq de la casa y coronada con coleslaw.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Pico-Negro-Smoked-pulled-sandwich.png"),
          },
        ],
      },
      {
        id: 16,
        titulo: "Smonkey",
        image: require("@/assets/Logos-carne/Smonkey_Logo-08.svg"),
        products: [
          {
            name: "Smonkey\n Criollo",
            description: "Sandwich de cerdo ahumado estilo Smonkey Criollo, con crujientes camotes fritos.",
            price: "32",
            image: require("@/assets/carne-fuego/platos/Smokey-Smonkey-Criollo.png"),
          },
          { name: "Chanchi papas oriental", 
            description: "Cortes tiernos y jugosos de cerdo ahumado estilo Smonkey Oriental, con crujientes papas amarillas fritas a las finas hierbas.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Smokey-Chanch-oriental.png"),
          },
        ],
      },
      {
        id: 17,
        titulo: "Doomo Saltado",
        image: require("@/assets/Logos-carne/DoomoSaltado_Logo-04.svg"),
        products: [
          {
            name: "Lomo\n Saltado",
            description: "Una presentación especial de nuestros reconocidos saltados. Con cortes de Certified Angus Beef americano (o proteína vegana Beyond Meat) y papas peruanitas. Todo saltado hasta conseguir ese ahumadito único que nos caracteriza.",
            price: "36",
            image: require("@/assets/carne-fuego/platos/Doomo-Saltado-Lomo-Saltado.png"),
          },
          { name: "Tequeños\n Cheeseburger", 
            description: "Irresistibles rollitos de masa primavera rellenos de Certified Angus Beef flameada y queso cheddar. Con un dorado crujiente y una textura inigualable, estas entradas desbordan sabor y te atraparán al primer mordisco (03 unid).",
            price: "20",
            image: require("@/assets/carne-fuego/platos/Doomo-Saltado-Tequeños-Cheeseburger.png"),
          },
        ],
      },
      {
        id: 18,
        titulo: "Fan",
        image: require("@/assets/Logos-carne/FAN_Logo-01.svg"),
        products: [
          {
            name: "Costillas bbq\n Fan",
            description: "Costillas Ahumada 8 horas en naranjo, bbq de ciruela japonesa, arroz frito con furikake.",
            price: "40",
            image: require("@/assets/carne-fuego/platos/FAN-Costillas-bbq-Fan.png"),
          },
          { name: "Conchitas Grana Padano - Yuzu", 
            description: "Concha de abanico a la parrilla, mantequilla de cítrico yuzu, queso grana padano, quinua crujiente, ciboulette.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/FAN-Conchitas-Grana-Padano-Yuzu.png"),
          },
        ],
      },
      {
        id: 19,
        titulo: "Tomo",
        image: require("@/assets/Logos-carne/TOMO.jpg"),
        products: [
          {
            name: "Choripan\n de pescado",
            description: "Chorizo de pescado y loche, crema de palta y encurtidos",
            price: "32",
            image: require("@/assets/carne-fuego/platos/TOMO-Choripan-pescado.png"),
          },
          { name: "Polla\n Yaki", 
            description: "Muslo de pollo a la parrilla,  papas y salsa de rocoto verde.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/TOMO-Polla-Yaki.png"),
          },
        ],
      },
      {
        id: 20,
        titulo: "Edo Sushi Bar",
        image: require("@/assets/Logos-carne/Edo_Logo-05.svg"),
        products: [
          {
            name: "Maki Parrillero\n (10 piezas)",
            description: "Maki relleno de langostino empanizado, espárrago y queso crema, envuelto en pescado sellado con salsa parrillera .",
            price: "29",
            image: require("@/assets/carne-fuego/platos/EDO-Parrillero.png"),
          },
          {
            name: "Maki Acevichado\n (10 piezas)",
            description: "Maki relleno de langostino empanizado y palta, envuelto en pescado bañado en salsa acevichada. ",
            price: "29",
            image: require("@/assets/carne-fuego/platos/EDO-Acevichado.png"),
          },
          {
            name: "Maki California\n (10 piezas)",
            description: "Maki relleno de salmón, palta y queso  crema.",
            price: "29",
            image: require("@/assets/carne-fuego/platos/EDO-California.png"),
          },
          {
            name: "Maki Shogun\n (10 piezas)",
            description: "Maki relleno de langostino empanizado y queso crema, envuelto en palta, bañado en salsa de anguila.",
            price: "29",
            image: require("@/assets/carne-fuego/platos/EDO-Shogun.png"),
          },
        ],
      },
      {
        id: 21,
        titulo: "Gyu",
        image: require("@/assets/Logos-carne/GYU_Logo-02.svg"),
        products: [
          {
            name: "Ossobuco al vino y leña",
            description: "Tierno ossobuco, coccion al vino y ahumado a la leña, vegetales en su salsa.",
            price: "35",
            image: require("@/assets/carne-fuego/platos/GYU-Ossobuco-al-vino-leña.png"),
          },
          { name: "Polenta y hongos a la parrilla", 
            description: "Cremosa polenta, parmesano y hongos a la parrilla.",
            price: "20",
            image: require("@/assets/carne-fuego/platos/GYU-Polenta-hongos-a-parrilla.png"),
          },
        ],
      },
/*       {
        id: 22,
        titulo: "Donde Maru",
        image: require("@/assets/carne-fuego/gyu.png"),
        products: [
          {
            name: "Tres leches\n Clásico",
            description: "cake de vainilla empapado con una deliciosa mezcla de 4 leches, por arriba merengue artesanal y canela ",
            price: "15",
            image: require("@/assets/carne-fuego/platos/GYU-Polenta-hongos-a-parrilla.png"),
          },
          { name: "Tres Leches\n de Nutella", 
            description: "cake de vainilla empapado con una deliciosa mezcla de 4 leches con Nutella y por arriba merengue artesanal ",
            price: "15",
            image: require("@/assets/carne-fuego/platos/GYU-Polenta-hongos-a-parrilla.png"),
          },
          { name: "Tres Leches\n de Matcha y vainilla", 
            description: "cake de vainilla empapado con una deliciosa mezcla de 4 leches con matcha y vainilla, por arriba merengue artesanal.",
            price: "15",
            image: require("@/assets/carne-fuego/platos/GYU-Polenta-hongos-a-parrilla.png"),
          },
        ],
      },
      {
        id: 23,
        titulo: "Cuchareable torta de Chocolate",
        image: require("@/assets/carne-fuego/gyu.png"),
        products: [
          {
            name: "Cuchareable torta de Chocolate",
            description: "Torta húmeda de chocolate rellena de fudge y manjarblanco",
            price: "20",
            image: require("@/assets/carne-fuego/platos/GYU-Polenta-hongos-a-parrilla.png"),
          },
          { name: "Cuchareable de Lúcuma", 
            description: "Torta húmeda de choolate con fudge y manjarblanco. Topping de manjarblanco de lúcuma.",
            price: "22",
            image: require("@/assets/carne-fuego/platos/GYU-Polenta-hongos-a-parrilla.png"),
          },
        ],
      },
      {
        id: 24,
        titulo: "Tarta de Queso",
        image: require("@/assets/carne-fuego/gyu.png"),
        products: [
          {
            name: "Tarta de queso tradicional",
            description: "Tarta de queso tradicional cremosa de 1 porción cuchareable. Horneado lentamente, base crocante de galleta, combinación de exquisitos quesos seleccionados y un gratinado dorado perfecto. Elaborado de forma artesanal. ",
            price: "20",
            image: require("@/assets/carne-fuego/platos/GYU-Polenta-hongos-a-parrilla.png"),
          },
        ],
      },
      {
        id: 25,
        titulo: "Delirio",
        image: require("@/assets/carne-fuego/gyu.png"),
        products: [
          {
            name: "Sandwich de Helado",
            description: "Tierno ossobuco, coccion al vino y ahumado a la leña, vegetales en su salsa.",
            price: "35",
            image: require("@/assets/carne-fuego/platos/GYU-Polenta-hongos-a-parrilla.png"),
          },
        ],
      }, */
    ],
    selectedItemId: null, // El ID del elemento seleccionado
  },
  mutations: {
    setCarta(state, carta) {
      state.carta = carta;
      console.log('carta:', carta);
    },
    setSelectedItemId(state, id) {
      state.selectedItemId = id;
      console.log('selectedItemId:', id); 
    },
  },
  // ...
  
});
